import { Link, NavLink } from "react-router-dom";
import logo from '../assets/images/logo-menu.png'
import { useContext, useEffect, useState } from "react";
import { getCategories, getParentCategory } from "../helper/backend-methods";
import {timestampToAlphabets} from "../common/index";
import 'react-loading-skeleton/dist/skeleton.css'
import { GlobalContext } from "../context";
import T from "../locale/translations-main";

function NavItems() {
    const { categoryList, updateCategoryList } = useContext(GlobalContext);
    const [showCategory, setShowCategory] = useState(false)
    const [genderList, setGenderList] = useState([])
    const [enableLeft, setEnableLeft] = useState(false);
    const [enableRight, setEnableRight] = useState(false);
    const [showNavigation, setShowNavigation] = useState(false);
    useEffect(()=>{
        if(!categoryList || !categoryList.length){
            getCategories().then(({data})=>{
                if(data.Status){
                    updateCategoryList(data.Data.CategoryItems)
                }
            }).catch((e)=>{
                console.log(e)
            })
        }
        getParentCategory().then(({data})=>{
            if(data.Status){
                setGenderList(data.Data)
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])

    useEffect(()=>{
        let isRLT = document.getElementsByTagName("body")[0].classList.contains("RTL")
        let parentWidth = document.getElementById("headerMenuWrapperParent").offsetWidth;
        let childWidth = 0;
        for (let index = 0; index < document.getElementById("headerMenuContainer").childNodes.length; index++) {
            childWidth += document.getElementById("headerMenuContainer").childNodes[index].offsetWidth;   
        }
        if(parentWidth < childWidth){
            setShowNavigation(true);
            if(isRLT){
                setEnableLeft(true);
            }
            else{
                setEnableRight(true);
            }
            initializeListener(document.getElementById("headerMenuWrapper"))
        }
    },[genderList.length])


    const toggleScroll = (direction) => {
        if(direction === "left"){
            let offset = document.getElementById("headerMenuWrapper").scrollLeft;
            document.getElementById("headerMenuWrapper").scrollLeft = offset-100;
            setEnableRight(true);
            setEnableLeft(false);
        }
        if(direction === "right"){
            let offset = document.getElementById("headerMenuWrapper").scrollLeft;
            document.getElementById("headerMenuWrapper").scrollLeft = offset+100;
            setEnableLeft(true);
            setEnableRight(false);
        }
    }

    const initializeListener = (e) => {
        e.addEventListener('scroll', function() {
            let isRLT = document.getElementsByTagName("body")[0].classList.contains("RTL");
            if (e.scrollLeft + e.clientWidth >= e.scrollWidth) {
                if(isRLT){
                    setEnableRight(true);
                }
                else{
                    setEnableRight(false);
                }
            }
            else{
                setEnableRight(true);
            }

            if (e.scrollLeft === 0) {
                if(isRLT){
                    setEnableLeft(true);
                }
                else{
                    setEnableLeft(false);
                }
            }
            else{
                setEnableLeft(true);
            }
        });
    }



    return (
        <section id="headerNavigation">
            <div className="wrapper d-flex align-items-center justify-between text-uppercase">
                <div className="header-categories">
                    <Link to={"/categories"} className="btn btn-primary category-btn"><i className="ri-menu-2-line mr-3"></i> <T>All Categories</T></Link>
                    <button className="btn btn-primary menu-btn" onClick={()=>{ setShowCategory(true) }}><i className="ri-menu-2-line mr-3"></i></button>
                </div>
                <div className="d-flex align-items-center justify-center menu-wrapper" id="headerMenuWrapperParent">
                    { showNavigation ? <button onClick={()=>{ toggleScroll("left") }} disabled={!enableLeft} className={"ri-arrow-left-line"} id="menuNavigateLeft"></button> : null }
                    <nav className="header-menu" id="headerMenuWrapper">
                        <ul className="header-menu-items d-flex align-items-center m-0 p-0" id="headerMenuContainer">
                            <li className="menu-item"><NavLink to={"/"} className="decoration-none text-no-wrap"><T>Home</T></NavLink></li>
                            {
                                genderList.map((item,index)=>{
                                    return <li key={index} className="menu-item"><Link to={"/products?gender="+item.CategoryId+"&t="+timestampToAlphabets()} className="decoration-none text-no-wrap">{item.CategoryName}</Link></li>
                                })
                            }
                            <li className="menu-item"><NavLink to={"/brands"} className="decoration-none text-no-wrap"><T>Brands</T></NavLink></li>
                        </ul>
                    </nav>
                    { showNavigation ? <button onClick={()=>{ toggleScroll("right") }} disabled={!enableRight} className={"ri-arrow-right-line"} id="menuNavigateRight"></button> : null }
                </div>
                <div className="header-offer">
                    <Link className="btn btn-alternative" to={"/account/occasions"}><i className="ri-calendar-line mr-2"></i><T>Occasions</T></Link>
                </div>
            </div>
            {
                showCategory ?
                <div className="all-category-wrapper">
                    <div className="all-category-overlay" onClick={() => setShowCategory(false)}></div>
                    <div className="all-categories-container">
                        <div className="menu-close" onClick={() => setShowCategory(false)}><i className="ri-close-line"></i></div>
                        <div className="all-category-menu">
                            <img src={logo} className="menu-logo"/>
                            <nav className="side-menu-options">
                                <ul className="header-menu-items p-0 mt-4 mb-0">
                                    <li className="menu-item"><NavLink to={"/"} onClick={() => setShowCategory(false)} className="decoration-none text-no-wrap"><T>Home</T></NavLink></li>
                                    { genderList.map((item,index)=>{ return <li key={index} className="menu-item"><Link onClick={() => setShowCategory(false)} to={"/products?gender="+item.CategoryId+"&t="+timestampToAlphabets()} className="decoration-none text-no-wrap">{item.CategoryName}</Link></li> })}
                                    <li className="menu-item"><NavLink onClick={() => setShowCategory(false)} to={"/brands"} className="decoration-none text-no-wrap"><T>Brands</T></NavLink></li>
                                    <li className="menu-item"><NavLink onClick={() => setShowCategory(false)} to={"/categories"} className="decoration-none text-no-wrap"><T>Categories</T></NavLink></li>
                                    <li className="menu-item"><NavLink onClick={() => setShowCategory(false)} to={"/account/occasions"} className="decoration-none text-no-wrap"><T>Occasions</T></NavLink></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div> : null
            }
        </section>
    )
}

export default NavItems;