export const getUserGUID = () => {
    let userObject = localStorage.getItem("userinfo");
    let guestCustomerGuid = localStorage.getItem("guestCustomerGuid");
    if(userObject && userObject.length){
        try {
            userObject = JSON.parse(userObject);
            if(userObject && userObject.CustomerGuid){
                return userObject.CustomerGuid
            }
            else{
                if(guestCustomerGuid){
                    return guestCustomerGuid
                }
                else{
                    return "";
                }
            }
        } catch (error) {
            if(guestCustomerGuid){
                return guestCustomerGuid
            }
            else{
                return "";
            }
        }
    }
    else{
        if(guestCustomerGuid){
            return guestCustomerGuid
        }
        else{
            return "";
        }
    }
}

export const getCurrentLocale = (full=false) => {
    let locale = localStorage.getItem("LANG");
    if (locale && locale.length) {
        locale = locale === "ar" ? "ar" : "en"
    }
    else {
        locale = "en";
    }
    return full ? locale+"-SA" : locale;
}

export const timestampToAlphabets = () => {
    const timestamp = Date.now();
    const alphabetMap = 'abcdefghijklmnopqrstuvwxyz';
    const alphaString = String(timestamp).split('').map(digit => alphabetMap[parseInt(digit)]).join('');
    return alphaString;
}